import Noty from "noty";
import "noty/src/noty.scss";
import "noty/src/themes/bootstrap-v4.scss";
document.addEventListener("DOMContentLoaded", function() {
  Noty.overrideDefaults({
    theme: "bootstrap-v4",
    timeout: 3000,
    visibilityControl: true,
  });

  if (gon.noty_message) {
    gon.noty_message.forEach(({ type, text }) => {
      new Noty({
        type,
        text,
      }).show();
    });
  }
});
window.Noty = Noty;

window.notyMessage = (text) => {
  new Noty({
    theme: "bootstrap-v4",
    type: "success",
    layout: "topRight",
    timeout: 3000,
    text: text,
  }).show();
}
window.notyWarning = (text) => {
  new Noty({
    theme: "bootstrap-v4",
    type: "warning",
    layout: "topRight",
    timeout: false,
    text: text,
  }).show();
};
window.notyError = (text) => {
  new Noty({
    theme: "bootstrap-v4",
    type: "error",
    layout: "topRight",
    timeout: false,
    text: text,
  }).show();
};